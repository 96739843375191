/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesWille: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Asuka Shikinami Langley: Wille guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_wille.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Asuka Shikinami Langley: Wille Guide & Review</h1>
          <h2>
            A guide & review for Asuka Shikinami Langley: Wille in NIKKE:
            Goddess of Victory.
          </h2>
          <p>
            Last updated: <strong>03/Mar/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Asuka Shikinami Langley: Wille" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="asuka-shikinami-langley-wille"
                  enablePopover
                />
              </div>
              <p>
                Evangelion… again! They really need to push out new content and
                stop with so much repetition. Anyways, let’s get back on track.
                With some basic math, we can find that this Wind pair is
                actually even stronger than their previous Fire element clones -
                in terms of dealing damage. But how strong are they?! Let’s find
                out with our review for Asuka: WILLE!
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Asuka 2.0 is, in simple term, a DPS unit. Her job is to deal
                damage, and oh boy, is she good at that! Her skills are mostly
                tied to her burst, to the point that more than half of her kit
                does not activate outside Burst Skill. <strong>However</strong>,
                she still offers much more than just fair damage even without
                bursting. If we do not factor in external buffs, she does the
                same amount of damage if not higher than the all famous
                Off-Burst DPS Pilgrim: Modernia - but in the Wind element. One
                edge Asuka 2.0 has over Modernia is that she does not need a
                core to deal damage. Majority of her damage comes from skills
                that cannot core hit.
              </p>
              <p>
                Her damage starts ramping up insanely hard whenever she uses her
                Burst Skill, which constitutes 50% the total damage she deals,
                despite the low 33% uptime. Nonetheless, it comes with a huge
                setback that can only be neutralized partly by Rei: Ayanami
                (tentative name), whom we will call Rei 2.0 in short.
              </p>
              <p>
                If you want to receive a TL;DR of how stacked she is, Asuka 2.0
                matches SBS in terms of DPS, core or not. At peak investment,
                SBS with Alice buff should deal slightly more damage than Asuka
                2.0 with Rei 2.0 assisting her. Against Wind-weak boss, though,
                the EVA 2.0 duo will easily clap our current goated SBS + Alice
                duo for the reason that Rei 2.0 herself deals heavy damage due
                to elemental advantages. Not to mention, players more often than
                not don’t use Alice with her full potential due to extremely
                tedious clicking, which means that for the majority of the
                playerbase, if they find a way to add Burst Generation to the
                team, this Wind Asuka-Rei duo is simply better than SBS-Alice
                duo for at least 4 out of 5 elements (exception being fire).
              </p>
              <p>
                Unlike other MGs, Asuka 2.0 packs quite a punch against mobs
                too, because her S1 will not hit one but two (and three if Rei
                2.0 is with her) targets. Definitely a 2025 limited DPS!
              </p>
              <h5>Should I get this unit?</h5>
              <p>
                Easiest answer since Rapi:RH: Yes, pull Asuka: Wille. She’s
                definitely meta. She is extremely viable in 2 out of 3 of the
                major contents: Bossing and Campaign, and is a bossing
                specialist mainly. She suffers in PvP due to its locked 30 FPS,
                but that’s about it. Not to mention, Asuka 2.0 is a true
                limited, which means she won’t return unless Shift Up can sign
                another contract with EVA, which is not highly likely.
              </p>
              <p>
                How about dupes? As will be mentioned later on, investments in
                her yield twice the value than usual. And since people will
                invest a lot in her equipment, she is likely to have tons of CP
                as well. What does this mean? While she might not be the right
                choice for all stages in high-deficit Campaign, the CP padding
                might make stages easier as that is basically equivalent to
                increasing the ATK of all units. There is also service in
                increased stat for other content.
              </p>
              <p>
                Also, yes, she is definitely worth the golden mileage if you
                fail to get her with pulls. MLB is advised if you are
                competitive or a fan of EVA but she performs well without 6%*
                base stats up from MLB (or 4%* at 2 LB). Be mindful that after
                this collaboration, we are guaranteed to be milked again with
                Half Anniversary looming around in 2 months. Brace yourself—a
                new meta age is here where Shift Up has decided to release power
                creeps upon power creeps. We definitely recommend saving mileage
                after this banner till Half Anniversary.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                As previously said, Asuka 2.0’s kit is very extreme in terms of
                what it activates.
              </p>
              <ul>
                <li>
                  Without bursting, she activates only one part of her Skill 1
                  and nothing else.
                </li>
                <li>
                  During her Burst, she dishes out tons of damage that
                  embarrasses most of our current DPS.
                </li>
              </ul>
              <h5>Anti A.T Field</h5>
              <blockquote>
                <p>
                  ■ Activates after landing 50 normal attack(s). Affects the
                  target(s).
                </p>
                <p>Deals 471.86% of final ATK as additional damage.</p>
                <p>
                  ■ Activates only when in Annihilation State status. Affects
                  self.
                </p>
                <p>Annihilation State's additional effect:</p>
                <p>
                  Target: Affects 2 enemy unit(s) within the attack range
                  nearest to the crosshair every 10 shot(s).
                </p>
                <p>Damage: Deals 15.62% of final ATK as damage.</p>
                <p>
                  Additional effect: Anti A.T. Field: Damage Taken ▲ 0.83% for
                  30 sec, stacks up to 30 time(s).
                </p>
              </blockquote>
              <p>This skill has 2 parts.</p>
              <p>
                Firstly, Asuka 2.0 deals a huge amount of bonus damage every 50
                shots (roughly ~0.85s) against the target her 50th bullet hits.
              </p>
              <p>
                This number, if we average it out per shot, will make her
                modifier 5.47% (can core hit) + 9.43% ATK. Compare this to
                Modernia whose modifier is 7.71% (can core hit) + 3.05% ATK.
              </p>
              <ul>
                <li>
                  Asuka 2.0 on core hit = 5.47*2+9.43 = 20.37% ATK per bullet
                </li>
                <li>
                  Modernia on core hit = 7.71*2+3.05 = 18.47% ATK per bullet
                </li>
              </ul>
              <p>
                This means that, even without bursting, Asuka 2.0 deals at least
                10% more damage than Modernia already. Well, that’s the rough
                math, because Modernia also buffs her own ATK by ~30%, hence in
                the end she will deal a bit more damage than Asuka. However, if
                you factor in Elemental Damage, in a one team mode (since you
                want to burst with Asuka in a multiple team mode), Asuka will
                deal more damage than Modernia as an off burst unit.
              </p>
              <p>
                But, it’s not the end. Skill 1 has additional parts that buff
                Asuka even more when she bursts:
              </p>
              <ul>
                <li>Small bonus damage every 10 shots</li>
                <li>Debuffs target so it takes more damage</li>
              </ul>
              <p>
                Testing verifies that her skill 1’s additional damage does not
                hit parts— and that it is only a visual feedback glitch. We have
                reported this bug to ShiftUp. Either they will fix the abnormal
                display, or they will add more damage to parts. Time will tell.
                For now, let’s assume she doesn’t hit parts since that is what
                normally happens.
              </p>
              <p>
                On top of the huge multiplier Asuka has, she also increases not
                just her own damage by up to 24.9% thanks to her Damage Taken
                debuff on enemies. This debuff is stacked every 10 shots, up to
                30 stacks, which means Asuka 2.0 will need to ramp up her damage
                over the first 5s before she can unleash peak damage. With Rei
                2.0, this ramp up is lowered to 3s only, helping Asuka 2.0 dish
                out even more DPS{' '}
                <strong>while also increasing everyone’s DPS too</strong>{' '}
                (because Damage Taken is a debuff on enemy, not a buff on a
                certain character).
              </p>
              <h5>GraGre But Better</h5>
              <blockquote>
                <p>
                  ■ Activates when entering Full Burst. Affects self when in
                  Annihilation State status.
                </p>
                <p>Attack Damage ▲ 30.97% for 10 sec.</p>
                <p>■ Activates when using Annihilation. Affects self.</p>
                <p>
                  Emergency Repair: Function: Reduces MG heating up speed and
                  clears ammo. Fixes recharge speed and restores HP based on the
                  Max HP proportion continuously.
                </p>
                <p>Effect 1: MG heating up speed ▼ 100% for 3 sec.</p>
                <p>Effect 2: Clears 100% of ammo.</p>
                <p>
                  Effect 3: Constantly recovers 3.77% of caster's final Max HP
                  every 1 sec over 3 sec.
                </p>
                <p>
                  Effect 4: Fixes the recharge speed with a 60% increase for 1
                  shot(s).
                </p>
              </blockquote>
              <p>
                This S2 won’t exist if Asuka 2.0 doesn’t burst. If she does, it
                will:
              </p>
              <ul>
                <li>Give Asuka 2.0 a good amount of Attack Damage</li>
                <li>
                  At the end of her burst, Asuka will do an awkward pause, which
                  triggers the following:
                </li>
                <ul>
                  <li>Deals damage to enemies affected by her S1.</li>
                  <li>Heals herself over the next 3s.</li>
                  <li>
                    Forces a reload, but the reload time is only 0.932s (40% of
                    her normal reload speed). This reload time is fixed, just
                    like Exia’s Treasure version.
                  </li>
                  <li>Forces heating up her gun longer than other MGs.</li>
                </ul>
              </ul>
              <p>
                It’s pretty much Grave’s S1 and quite simple - you unload a
                lethal barrage during your burst, so it is sensible to cool down
                for a while. Problem is, you don’t want her to stop shooting
                because her damage outside her Burst is also impressive. You can
                do that by pairing her with Rei 2.0 or try putting Ade/Clay in
                the team, who will{' '}
                <strong>remove this debuff but also other buffs too.</strong>{' '}
                (Don’t use Ade/Clay with her).
              </p>
              <p>
                After verifying Rei 2.0’s mechanic, we learned that she only
                halves the wind-up time of MG. It means with Rei 2.0, Asuka will
                still need 2.33s to wind up normally. For other MGs (like Crown,
                Rapi: RH), they will still need 1.15s (half of 2.3s) to wind up,
                meaning that this part of the synergy between Rei and Asuka is
                not particularly special.
              </p>
              <p>
                The heal part is just okay, not too impressive like Fire Asuka
                where she can tank everything with her lifesteal.
              </p>
              <h5>I’m Gonna Annihilate You</h5>
              <blockquote>
                <p>■ Affects self.</p>
                <p>
                  Annihilation State: Function: Reduces the normal attack damage
                  while increasing attack capabilities.
                </p>
                <p>
                  Effect 1: Normal Attack Damage Multiplier ▼ 40% for 9 sec.
                </p>
                <p>Effect 2: Reloads 21% magazine(s).</p>
                <p>Effect 3: ATK ▲ 46.8% of caster's ATK for 9 sec.</p>
                <p>Effect 4: Attack Damage ▲ 36% for 9 sec.</p>
                <p>■ Affects the target(s) afflicted with Anti A.T. Field.</p>
                <p>
                  Annihilation:Function: After Annihilation State ends, fires
                  powerful attacks at targets affected by Anti A.T. Field.
                </p>
                <p>Deals 6.62% of final ATK as additional damage.</p>
                <p>
                  Mirrors the stack count of Anti A.T. Field for certain
                  targets.
                </p>
                <p>
                  Anti A.T. Field status is removed after the effect is
                  triggered.
                </p>
              </blockquote>
              <p>
                The root of Asuka 2.0’s strength and also the reason why so many
                people gave up comprehending Asuka 2.0’s kit when Shift Up first
                introduced it. The kit is so angelically long that it looks like
                a <i>thesis</i> at this point, a <i>cruel</i> one if you get it
                ha ha.
              </p>
              <p>
                Long story short, during her burst, Asuka 2.0 will have access
                to:
              </p>
              <ul>
                <li>A moderate amount of ATK buff.</li>
                <li>
                  A moderate amount of Attack Damage (actually huge as it stacks
                  with her S2).
                </li>
                <li>An immediate ammo refund (think of Noir).</li>
                <li>Lowered normal attack multiplier (-40%).</li>
              </ul>
              <p>
                Yep, that’s a way to keep Asuka 2.0’s DPS within boundary. Her
                final damage distribution during her Burst will be like:
              </p>
              <ul>
                <li>3.282% ATK that can core hit.</li>
                <li>9.43% ATK from base S1.</li>
                <li>1.562% ~ 4.686% ATK from S1 during her burst.</li>
              </ul>
              <p>
                This means her normal auto attack will only contribute around
                max 46% against a core target without parts. It will be reduced
                to a mere 19% of total damage only against non-core 2-part
                target.
              </p>
              <p>
                If we look at the kit overall, Asuka 2.0 deals a huge amount of
                damage with moderate–high amounts of buffs only. This means she
                will scale well with other meta supports right now, unlike her
                previous version who suffers from diluted effects.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="ss">SS</strong> | Campaign
                (HD) - <strong className="ss">SS</strong>
              </h5>
              <p>
                Asuka 2.0 is a strong unit to use in Campaign mainly because of
                how much damage she is able to dish out, and also because of the
                fact that she can provide a team buff when she bursts by
                debuffing the enemies to take more damage. Not only does she
                deal quite the same damage as Modernia as an off burst unit,
                especially on her strong element, but she also kills mobs faster
                if one can aim well enough. She can be used as an in-burst B3
                too, and does not brick (i.e lower) her DPS like Modernia if she
                bursts. Instead, it further amplifies her insanely high single
                target damage and also manages to hit multiple enemies every
                second. It is also probably time to accept that the days of
                Modernia as main B3 in Campaign is a thing of the past.
              </p>
              <p>
                While the current meta is shifted a lot towards elemental meta,
                we do not expect Asuka 2.0 to be used at the same level as Rapi
                Hood, Red Hood or Alice. However, we believe she is a suitable
                option even for neutral elements, and is an insanely good option
                for wind weak stages alongside the current queen of Wind:
                Scarlet Black Shadow. We really do still need water DPS for
                campaign Shift Up, if you’re reading this.
              </p>
              <h5>
                Bossing - <strong className="sss">SSS</strong>
              </h5>
              <p>
                We will keep this short since we’ve already mentioned it at
                least 5 times that her DPS is super high. Definitely one of the
                strongest Wind DPS now overall, competing directly with SBS
                while also buffing her team's damage to another level. She’s so
                busted that we expect EVA 2.0 to become a staple team like Alice
                - SBS, Cindy funneling comp, or Fire Team. And when the boss has
                parts, she can even act like a splash MG. Mirror mirror on the
                wall, when will Shift Up release Skill Resets, release an iron
                weak raid, and stop releasing meta units every banner and give
                us a break???
              </p>
              <h5>
                PVP - <strong className="c">C</strong>
              </h5>
              <p>
                As an MG, Asuka 2,0 automatically has a low rating in PVP as she
                suffers from extremely low burst gen and tendency to feed
                Jackal, not to mention the lower fire rate because of 30. Asuka
                is NOT a unit to be used for PvP.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                Asuka 2.0 scales pretty well with good OL lines, and gear should
                be prioritized over skills.
              </p>
              <p>
                We have tested that with around 165%~191% Max Ammo (3 lines with
                moderate values), and Bastion Cube Lv. 7, Asuka will ONLY RELOAD
                after her own Burst ends (forced reload). This will tremendously
                increase her DPS because you don’t lose uptime outside this dead
                time.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> 3× Max Ammo
                </li>
                <li>
                  <strong>Ideal:</strong> 4× ELE, 4× ATK
                </li>
                <li>
                  <strong>Passable:</strong> -
                </li>
                <li>
                  <strong>Priority:</strong> Meta
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <p>
                In contrast with other DPS, Asuka’s skill has a huge base but
                low scaling. This makes investing in her skills much more
                comfortable. You can save some resources for Rei 2.0, who should
                be a Scarlet 2.0 look-alike but slightly better.
              </p>
              <ul>
                <li>
                  <strong>Skill 1: 7~10</strong>
                </li>
                <ul>
                  <li>
                    Simply the best skill to upgrade. Increases team buff and
                    her DPS immensely, almost 10-fold more effective to upgrade
                    this skill in comparison to Skill 2. Since this skill is of
                    so high importance, aim to max it out.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 4~7</strong>
                </li>
                <ul>
                  <li>
                    Unless or until you are a competitive player, you do not
                    need this skill at 10. She serves her purpose completely
                    fine at Lv. 4, and you can leave it at that. However, as you
                    progress, we recommend upgrading this skill to 7 since it is
                    worth it to increase Asuka’s damage. Besides, the cost till
                    Lv. 7 is not considered high in late game. Eventually, it is
                    a 10 if you have extra skill books lying around since after
                    all it is a DPS increase.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill:7</strong>
                </li>
                <ul>
                  <li>
                    Some personal damage buffs and a small nuke. Asuka’s Burst
                    Skill has the same relative problem as her Skill 2, where
                    when it is compared to her Skill 1, the damage increase is
                    comparatively low. However, it is still a DPS increase so we
                    recommend getting it to 7 since meta units have high enough
                    DPS that the material trade-off is acceptable. However, we
                    do not recommend getting her Burst to 10 unless you are a
                    competitive player or are looking to maximize her DPS.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="asuka-shikinami-langley-wille" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <StaticImage
                src="../../../images/nikke/generic/cube_13_sm.webp"
                alt="Guide"
              />
              <p>
                Bastion is definitely the 2nd if not best cube for her because
                it lowers the amount of ammo required by 42% (at lv 7).
                Resilience is quite irrelevant because after bursting, Asuka is
                forced to reload at 60% reload speed reduction, so having a cube
                or not makes no change. And if Rei 2.0 could remove wind up
                during the other burst, then we don’t even need a Resilience
                Cube for this unit at all.
              </p>
              <p>
                What will be the best cube for her you may ask? Of course, it’s
                the new cube Destruction Cube (increases part damage by 30%).
                Remember, Asuka 2.0 can hit parts too and gain bonus damage from
                them. When the boss has strong parts, this cube will be the best
                on every character. Otherwise, select Bastion cube for this
                unit.
              </p>
              <h5>Doll Investments</h5>
              <p>
                The effect of MG Doll is pretty meh, but it gives Asuka a lot of
                raw stats, which are more than welcome. The damage reduction is
                very strong too (most people seem to overlook it), allowing some
                teams to run no healer on certain bosses (therefore Shift Up
                starts introducing heavy damage bosses again to sell their
                healers). You also want the CP boost from SR rarity doll too to
                help you push in Main Tower and Campaign if you plan to use
                Asuka there. SR 5 if you want to save materials. SR15 if you are
                looking to clear Kraken or are looking to squeeze more damage
                out of her during Raids…
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: Asuka x Rei Team Up Again</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                    <NikkeCharacter mode="icon" slug="grave" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley-wille"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami-tentative-name"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                The strongest no-core wind comp, featuring 2 EVA units. Liter
                Crown and Helm are the best supporters if you want to maximise
                Asuka and Rei’s damage. Since reload is quite irrelevant, you
                can switch between Grave and Crown depending on the situation
                (Crown is still GOAT against A.I Kraken though). RapiRH is the
                best if you are looking for just a single team comp and not for
                multiple teams. If Rei removes wind-up, Rapi and Modernia might
                be the staple alongside her even on Raids where multiple teams
                are required.
              </p>
              <p>
                Both Raids or Wind-weak Campaign should fear this team. It can
                find itself being used outside Wind-weak Raids, but we will have
                to see how Rei practically performs first before giving you a
                100% on that.
              </p>
              <h5>Team #2: The Wind Terror </h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="scarlet-black-shadow"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley-wille"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="helm-treasure"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                Wind element is in business now that they have another mega DPS
                unit to use. In the end, it’s better to run SBS and AsukaW
                together without Rei or even Alice to achieve the highest result
                against a Wind-weak boss. This is the best team to use straight
                up for Wind Weak Bosses.
              </p>
              <h5>Team #3: Tornado Bunnies</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rapi-red-hood"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="asuka-shikinami-langley-wille"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rei-ayanami-tentative-name"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noir" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Bunnies in 2025? Well they still have their use, especially in
                running braindead MG comps. Noir being a Wind unit helps her
                cause as well. This comp provides a lot of healing amount, a lot
                of ammo for Rapi and Asuka to shoot (due to Noir’s + Asuka’s
                anmo refund). Bread and butter team, use it when you are too
                lazy to even think. Machine Guns love ammo and make Noir a very
                happy relevant unit.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <p>No. Don’t use MG, especially MG DPS in PVP.</p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Strongest Wind DPS as of her release.</li>
                        <li>
                          Even as a non burst B3 DPS, she can dish out very high
                          DPS, especially on her own element.
                        </li>
                        <li>
                          Unconditionally strong; does not depend on whether the
                          boss has a core or part. She’s even stronger when the
                          boss has them.
                        </li>
                        <li>
                          Cheaper to build versus recent heavy-duty units:
                          requires mainly Skill 1 only.
                        </li>
                        <li>
                          Zankoku na Tenshi no Tēze whenever you open the game.
                          Based.
                        </li>
                        <li>Top 5 MLB lobby screens.</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Relies a lot on OL lines to reach maximum potential.
                        </li>
                        <li>
                          Skill scaling is not that great for Skill 2 and Burst
                          compared to skill 1, which means even if you want to
                          improve her output, there won’t be much to do.
                        </li>
                        <li>
                          True limited collaboration character. Now or never.
                          Can't get copies in the future.
                        </li>
                        <li>
                          FPS-dependent, hence she won’t perform well on low-end
                          to medium-spec devices.
                        </li>
                        <li>Weaker in PVP due to forced 30 FPS.</li>
                        <li>
                          Not sure how to feel about another Evangelion collab,
                          but the MLB screen is super.
                        </li>
                        <li>
                          Might as well read a scientific journal than read her
                          frustrating kit.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesWille;

export const Head: React.FC = () => (
  <Seo
    title="Asuka Shikinami Langley: Wille Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Asuka Shikinami Langley: Wille in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
